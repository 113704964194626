<template>
    <div style="width:100%;color:#606266">
        <div class="users">
            <el-button type="primary" @click="showInfo=true">加入房间</el-button>
            <el-button type="primary" v-if="isInRoom" @click="publishScreen">屏幕分享</el-button>
            <el-button type="warning" v-if="isInRoom" @click="leaveRoom">离开房间</el-button>
            <p>PPT</p>
            <div id="ppt" style="width:100%"></div>
            <p>选手</p>
            <div id="student" class="smallWindow"></div>
            <p>评委</p>
            <div id="educator" class="smallWindow"></div>
        </div>
        <el-dialog :visible.sync="showInfo">
          <p>请输入英文名</p>
          <el-input v-model="userId" placeholder="请输入英文名"></el-input>
          <el-button type="primary" @click="joinRoom" style="margin-top:20px">加入房间</el-button>
        </el-dialog>
    </div>
</template>

<script>
import * as QNRTC from 'pili-rtc-web'
import { createPresentationRoomToken, getPresentationInfo,getStudent,getApplicationsAdmin } from '../api/keneng'
import {getUserId} from '../utils/store'
import {formatDate} from '../utils/date'
const myRoom = new QNRTC.TrackModeSession()

console.log('current version is', QNRTC.version)
export default {
  data () {
    return {
      audioDevice: '',
      videoDevice: '',
      audioDevices: [],
      videoDevices: [],
      roomToken: '',
      userList: [],
      currentUser: '',
      lastUser: '',
      showInfo: false,
      showToken: false,
      room: '',
      userId: '',
      userToken: '',
      room_list: [],
      type: '',
      currentRoom: '',
      message:'',
      toUserId:'',
      showChat:false,
      chatMessage:[],
      allMessages:{},
      newMessages:[],
      isInRoom:false,
      room_id:'',
      time_slot:[],
      publicMessages:[],
      showPublic:[],
      roomId:'',
      screenTrackId:'',
      screenTrack:'',
      remoteTrackId:'',
      room_activity:'Presentation',
      userInfo:[]
    }
  },
  async mounted () {
    this.roomId = this.$route.query.roomId
 
   
    // this.getPresentationInfo()
    window.addEventListener( 'beforeunload', e => this.leaveRoom() );
  },
  destroyed(){
      myRoom.leaveRoom();
  },
  methods: {
    addDeviceToSelect (deviceInfo) {
      let audioDevices = []
      let videoDevices = []
      deviceInfo.forEach(info => {
        if (info.kind === 'audioinput') {
          audioDevices.push(info)
        } else if (info.kind === 'videoinput') {
          videoDevices.push(info)
        }
      })
      this.audioDevices = audioDevices
      this.videoDevices = videoDevices
    },
    async joinRoom () {
      // 从输入框中获取 roomToken

      this.showInfo=false

      let roomToken

      const result = await this.createRoomToken()
      if(result.code==0){
        roomToken = result.data.token
        this.room_id = result.data.room_id
      }else{
        this.$message(result.msg)
        return;
      }

      try {
        // 加入房间
        await myRoom.joinRoomWithToken(roomToken)

        // 如果加入房间后发现房间已经有人发布，就订阅他
      
        this.isInRoom=true
        this.subscribeTracks(myRoom.trackInfoList)


      } catch (e) {
        console.error(e)
        this.isInRoom = false
        this.$message(`加入房间失败！ErrorCode: ${e.code || ''}`)
        return
      }


        myRoom.on("track-add", (tracks) => {
         

            let screen = tracks.filter(item=>{
                return item.tag=='screen'
            })
            if(screen.length!==0){
              if(this.screenTrackId!=''){
                myRoom.unpublish([this.screenTrackId]);
                this.screenTrack.release();
              }
              if(this.remoteTrackId!=''){
                myRoom.unsubscribe([this.remoteTrackId]);
              }
            }
            this.subscribeTracks(tracks);         
        });

        myRoom.on("user-leave", user => {
            let thisNode=document.getElementById(user.userId);
            thisNode.parentElement.removeChild(thisNode);
        })


      await this.publish()


    },
    updateMessage(){
      this.chatMessage = this.allMessages[this.toUserId]
    },

    async publishScreen () {
      if(this.screenTrackId!=''){
        this.$message('请勿重复分享！')
        return;
      }
      let tracks
      try {
        // 通过用户在页面上指定的设备发起采集
        // 也可以不指定设备，这样会由浏览器自动选择
        tracks = await QNRTC.deviceManager.getLocalTracks({
          screen: {
              enabled: true,
              source: "window",
              bitrate: 600,
              height: 720,
              width: 1280,
              tag:'screen'
          },
          audio: {
              enabled: false,
          }
        })
      } catch (e) {
        console.error(e)
        this.$message(`采集失败，请检查您的设备。ErrorCode: ${e.code}`)
        return
      } 


      try {
        // 发布采集流
        await myRoom.publish(tracks)
      } catch (e) {
        console.error(e)
        this.$message(`发布失败，ErrorCode: ${e.code}`)
      }
      
      this.screenTrackId=tracks[0].info.trackId
      this.screenTrack = tracks[0]

      if(this.remoteTrackId!=''){
        myRoom.unsubscribe([this.remoteTrackId]);
        this.remoteTrackId=''
      }

      let ppt = document.getElementById('ppt');
      for (const track of tracks) {
            if (track.info.kind === "video") {
               
                track.play(ppt, true);
            }
        }
    },
    async publish () {
      let tracks
      try {
        // 通过用户在页面上指定的设备发起采集
        // 也可以不指定设备，这样会由浏览器自动选择
        tracks = await QNRTC.deviceManager.getLocalTracks({
          video: {
            enabled: true,
            deviceId: this.videoDevice,
            bitrate: 1000,
            height:480,
            width:640,
            tag:'camera'
          },
          audio: {
            enabled: true,
            tag:'audio',
            deviceId: this.audioDevice
          }
        })
      } catch (e) {
        console.error(e)
        alert(`采集失败，请检查您的设备。ErrorCode: ${e.code}`)
        return
      }


        for (const track of tracks) {
            if (track.info.kind === "video") {
                let div = document.createElement('div');
                div.setAttribute('id',track.userId);
                div.style.width = '25%';
                let educator = document.getElementById('educator');
                educator.appendChild(div)
                track.play(div, true);
            }
        }


      try {
        // 发布采集流
        await myRoom.publish(tracks)
      } catch (e) {
        console.error(e)
        alert(`发布失败，ErrorCode: ${e.code}`)
      }

    },
    subscribeTracks (trackInfoList) {
        console.log(2222222,trackInfoList)
        let screen = trackInfoList.filter(item=>{
          return item.tag=='screen'
        })
        let videoAudio = trackInfoList.filter(item=>{
          return item.tag!='screen'
        })
        let student = document.getElementById('student');
        let educator = document.getElementById('educator');
        myRoom.subscribe(videoAudio.map(t => t.trackId)).then(tracks => {
            for (const track of tracks) {
                    if(track.info.kind === "video"){
                        if(track['userId'].indexOf('educator')!=-1){
                                let div = document.createElement('div');
                                div.setAttribute('id',track.userId);
                                div.style.width = '25%';

                                let play = document.createElement('div');
                                play.style.width = '100%';
                                div.appendChild(play);
                                track.play(div,true);

                                let txt = document.createElement('div');
                                txt.style.width = '100%';
                                txt.style.textAlign='center'
                                txt.innerHTML=track.userId
                                div.appendChild(txt);

                                educator.appendChild(div);
                          
                        }else{
                                let div = document.createElement('div');
                                div.setAttribute('id',track.userId);
                                div.style.width = '25%';
                                student.appendChild(div);

                                let play = document.createElement('div');
                                play.setAttribute('id',track.userId+'1')
                                play.style.width = '100%';
                                div.appendChild(play);
                                track.play(play, true);

                                let txt = document.createElement('div');
                                txt.style.width = '100%';
                                txt.style.textAlign='center'
                                txt.innerHTML=track.userId
                                div.appendChild(txt);
                        }
                    }else{
                      track.play(student)
                    }
            }
        });
        let ppt = document.getElementById('ppt')
        myRoom.subscribe(screen.map(t => t.trackId)).then(tracks => {
            for (const track of tracks) {
                    if(track.info.kind === "video"){
                       track.play(ppt,true)
                       this.remoteTrackId=track.info.trackId
                       this.screenTrackId=''
                    }
            }
        });
    },
    createRoomToken () {
      return new Promise((resolve,reject)=>{
        createPresentationRoomToken(this.roomId,'educator-'+this.userId).then(res => {
          resolve(res.data)
        })
      })
      
    },
    listRoomUsers (e) {
      if(e){
        if(this.isInRoom==true){
          if(this.room_id!==''){
              listRoomUsers(this.room_id).then(res => {
                console.log(res)
                this.userList = res.data
              })
          }
        }
      }
    },
    changeView () {
      for (let item of this.userList) {
        if (item.userId == this.currentUser) {
          myRoom.setMergeStreamLayout(this.currentUser, {
            x: 0, y: 0, w: 1920, h: 1080, hidden: false, muted: false
          })
        } else {
           myRoom.setMergeStreamLayout(item.userId, {
            x: 0, y: 0, w: 0, h: 0, hidden: false, muted: false
          })
        }
      }
    },
   
   
    leaveRoom(){
      myRoom.leaveRoom();
    
    },
    getPresentationInfo(){
      let student_id = getUserId()
      getPresentationInfo(student_id,'presentation').then(res=>{
        if(res.data.code===0){
          this.time_slot = res.data.data
        }
      })
    },
    show(){
        // console.log(myRoom)
        // 房间当前的用户列表
        // const users = myRoom.users;
        const currentTrackInfoList = myRoom.trackInfoList;
        console.log(111,currentTrackInfoList)
        // 房间当前的用户列表
        // const users = myRoom.users;
        // for (const user of users) {
        // // 每个用户当前的 TrackInfo 列表
        // const userTrackInfoList = user.publishedTrackInfo;
        
    },
    async getApplicationsAdmin(){
      return new Promise((resolve,reject)=>{
        getApplicationsAdmin(this.roomId,this.room_activity).then(res=>{
          resolve(res)
        })
      })  
    }
  }
}
</script>

<style >
select {
  width: 300px;
}
.users{
    padding:20px
}

section {
  margin-bottom: 20px;
}

.qnrtc-stream-player {
  width: 100% !important;
  /* height: 1080px !important; */
  background: #000;
}

.smallWindow{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
</style>